<template>
  <div class="match-height">
    <h4>จัดการสมาชิก</h4>
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <b-card
          header-tag="header"
          header-bg-variant="warning"
          header-class="justify-content-center"
          class="text-center"
        >
          <template #header>
            <h4 class="mb-0 text-white font-weight-bolder">
              สมาชิกใหม่
            </h4>
          </template>
          <p class="mt-3 text-total">
            {{ newaccount }}
          </p>
        </b-card>
      </div>
      <div class="col-md-6 col-lg-4">
        <b-card
          header-tage="header"
          header-bg-variant="info"
          header-class="justify-content-center"
          class="text-center"
        >
          <template #header>
            <div class="d-flex justify-content-center">
              <h4 class="mb-0 text-white font-weight-bolder">
                สมาชิกทั้งหมด
              </h4>
            </div>
          </template>
          <p class="mt-3 text-total">
            {{ allaccount }}
          </p>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
    startdate: {
      type: String,
      default: '',
    },
    enddate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newaccount: 0,
      allaccount: 0,
    }
  },
  watch: {
    startdate() {
      this.LoadAllAccount()
    },
    enddate() {
      this.LoadAllAccount()
    },
  },
  created() {
    this.LoadAllAccount()
  },
  methods: {
    LoadAllAccount() {
      this.$store.dispatch('dashboard/getAllAccount', { startdate: this.startdate, enddate: this.enddate }).then(result => {
        this.newaccount = result.data.totalDate
        this.allaccount = result.data.totalAll
        console.log(result)
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
.text-total {
    font-size:3rem;
    font-weight: bolder;
}
</style>
