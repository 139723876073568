<template>
  <div class="container-fluid">
    <h4>Dashboard</h4>
    <b-card>
      <div class="row">
        <div class="col-12 col-md-6 col-xl-6">
          <div class="row">
            <div class="col-12 col-md-6 col-xl-6">
              <label>เริ่มต้น</label>
              <date-picker
                ref="updateDate"
                :date="startdate"
                :type="'start'"
                :status="false"
                @setDatePicker="onSetDatePicker"
              />
            </div>
            <div class="col-12 col-md-6 col-xl-6">
              <label>สิ้นสุด</label>
              <date-picker
                :date="enddate"
                :type="'end'"
                :status="false"
                @setDatePicker="onSetDatePicker"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-6 mt-2">
          <div class="row">
            <div class="col-12 col-md-4 col-xl-4">
              <b-button
                :variant="outline[0]"
                block
                @click="onSetbetween(0)"
              >
                วันนี้
              </b-button>
            </div>
            <div class="col-12 col-md-4 col-xl-4">
              <b-button
                :variant="outline[1]"
                block
                @click="onSetbetween(7)"
              >
                7 วันที่แล้ว
              </b-button>
            </div>
            <div class="col-12 col-md-4 col-xl-4">
              <b-button
                :variant="outline[2]"
                block
                @click="onSetbetween(30)"
              >
                30 วันที่แล้ว
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <card-account
      :startdate="startdate"
      :enddate="enddate"
    />
    <hr>
    <card-transaction
      :startdate="startdate"
      :enddate="enddate"
    />
  </div>
</template>
<script>
import moment from 'moment'
import { BCard, BButton } from 'bootstrap-vue'
import DatePicker from '../component/datePicker.vue'
import CardAccount from './cardAccount.vue'
import CardTransaction from './cardTransaction.vue'

export default {
  components: {
    BCard,
    BButton,
    CardAccount,
    CardTransaction,
    DatePicker,
  },
  data() {
    return {
      name: '',
      startdate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      enddate: moment().format('YYYY-MM-DD'),
      type: '',
      outline: ['outline-primary', 'primary', 'outline-primary'],
    }
  },
  watch: {
    type(val) {
      for (let i = 0; i < 3; i += 1) {
        this.outline[i] = 'outline-primary'
      }
      if (val === 0) {
        this.outline[0] = 'primary'
      } else if (val === 7) {
        this.outline[1] = 'primary'
      } else if (val === 30) {
        this.outline[2] = 'primary'
      }
    },
    startdate(e) {
      console.log(e)
    },
    enddate(e) {
      console.log(e)
    },
  },
  methods: {
    onSetbetween(val) {
      if (val === 0) {
        this.startdate = moment().format('YYYY-MM-DD')
      } else {
        this.startdate = moment().subtract(val, 'days').format('YYYY-MM-DD')
      }
      this.type = val
      this.$refs.updateDate.setdate(this.startdate)
    },
    onSetDatePicker(data) {
      console.log(data)
      if (data.type === 'start') {
        this.startdate = moment(data.val).format('YYYY-MM-DD')
      } else this.enddate = moment(data.val).format('YYYY-MM-DD')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
