<template>
  <div>
    <h4>จัดการธุรกรรม</h4>
    <div class="row">
      <div class="col-md-6 col-lg-4 col-12">
        <b-card
          header-tag="header"
          header-bg-variant="success"
          header-class="justify-content-center"
          class="text-center"
        >
          <template #header>
            <div class="d-flex justify-content-center">
              <h4 class="mb-0 text-white font-weight-bolder">
                ฝากเครดิต
              </h4>
            </div>
          </template>
          <p class="mt-3 text-total">
            {{ deposit }}
          </p>
        </b-card>
      </div>
      <div class="col-md-6 col-lg-4 col-12">
        <b-card
          header-tag="header"
          header-bg-variant="danger"
          header-class="justify-content-center"
          class="text-center"
        >
          <template #header>
            <div class="d-flex justify-content-center">
              <h4 class="mb-0 text-white font-weight-bolder">
                ถอนเครดิต
              </h4>
            </div>
          </template>
          <p class="mt-3 text-total">
            {{ withdraw }}
          </p>
        </b-card>
      </div>
      <div class="col-md-6 col-lg-4 col-12">
        <b-card
          header-tag="header"
          header-bg-variant="secondary"
          header-class="justify-content-center"
          class="text-center"
        >
          <template #header>
            <div class="d-flex justify-content-center">
              <h4 class="mb-0 text-white font-weight-bolder">
                งานรอดำเนินการ
              </h4>
            </div>
          </template>
          <p class="mt-3 text-total">
            {{ waiting }}
          </p>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
    startdate: {
      type: String,
      default: '',
    },
    enddate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      deposit: 0,
      withdraw: 0,
      waiting: 0,
    }
  },
  watch: {
    startdate() {
      console.log('test')
      this.LoadData()
    },
    enddate() {
      console.log('test')
      this.LoadData()
    },
  },
  created() {
    this.LoadData()
  },
  methods: {
    LoadData() {
      this.$store.dispatch('dashboard/getAllTransaction', { startdate: this.startdate, enddate: this.enddate }).then(result => {
        // eslint-disable-next-line no-underscore-dangle
        const deposit = result.data.totalAll.find(x => x._id === 'เพิ่มเครดิต').total !== undefined ? result.data.totalAll.find(x => x._id === 'เพิ่มเครดิต').total : 0
        this.deposit = deposit
        // eslint-disable-next-line no-underscore-dangle
        this.withdraw = result.data.totalAll.find(x => x._id === 'ถอนเครดิต').total !== undefined ? result.data.totalAll.find(x => x._id === 'ถอนเครดิต').total : 0
        this.waiting = result.data.totalWaiting
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
.text-total {
    font-size:3rem;
    font-weight: bolder;
}
</style>
